import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
      path: '/',
      name: 'index',
      component: () => import('../page/indexPage.vue')
  },
  {
      path: '/product',
      name: 'product',
      component: () => import('@/page/productPage.vue')
  },
  {
      path: '/technology',
      name: 'technology',
      component: () => import('@/page/technologyPage.vue')
  },
  {
      path: '/case',
      name: 'case',
      component: () => import('@/page/casePage.vue')
  },
  {
      path: '/team',
      name: 'team',
      component: () => import('@/page/teamPage.vue')
  },
  {
      path: '/about',
      name: 'about',
      component: () => import('@/page/aboutPage.vue')
  },
  {
      path: '/contact',
      name: 'contact',
      component: () => import('@/page/contactMe.vue')
  }
  ,
  {
      path: '/business',
      name: 'business',
      component: () => import('@/page/businessPage.vue')
  }
  ,
  {
      path: '/download',
      name: 'download',
      component: () => import('@/page/downloadPage.vue')
  }
  ,
  {
      path: '/hire',
      name: 'hire',
      component: () => import('@/page/hirePage.vue')
  }
  // 定义路由规则，例如 { path: '/', component: YourComponent }  
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
