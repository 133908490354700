<template>
  <router-view/>
</template>

<style lang="scss">
  *{
    margin: 0;
    padding: 0;
  }
</style>
